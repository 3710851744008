<template>
    <div class="pay-box" v-if="show">
        <div class="pay-main">
            <img class="close-icon" @click="closeBox" src="../../static/icon/close-icon.png">
            <img v-if="type === 0" class="back-icon" @click="goBack" src="../../static/icon/go-back.png">
            <span class="pay-title">扫码付费</span>
            <span class="pay-type-title" v-if="type === 1">订单详情</span>
            <div class="order-info" v-if="type === 1">
                <img v-if="payType === 1" class="order-banner" src="../../static/images/dingyue-bg.png">
                <div class="order-main">
                    <span class="order-title">
                        <span class="blue" v-for="(item, index) in orderInfo.tags" :key="index">#{{ item }}</span>
                        <span>{{ orderInfo.title }}</span>
                    </span>
                    <span v-if="payType === 2" class="description">{{ orderInfo.description }}</span>
                    <div class="order-bottom" :style="payType === 2 ? 'margin-top:16px' : ''">
                        <span class="order-bottom-txt1">{{ payType === 1 ? '订阅' : '文章' }}</span>
                        <span class="order-bottom-txt2">数量1</span>
                    </div>
                </div>
            </div>
            <span class="pay-type-title" v-if="type === 1">支付方式</span>
            <div class="pay-type" v-if="type === 1">
                <div class="pay-type-item" :class="platform === 2 ? 'pay-type-item-active' : ''" @click="platform = 2">
                    <img @click="platform = 2" src="../../static/icon/wechat-icon.png">
                    <span>微信支付</span>
                </div>
                <div class="pay-type-item" :class="platform === 3 ? 'pay-type-item-active' : ''" @click="platform = 3">
                    <img @click="platform = 3" src="../../static/icon/zfb-icon.png">
                    <span>支付宝支付</span>
                </div>
            </div>
            <div class="btn-box" v-if="type === 1">
                <div class="go-pay-btn" @click="generateArticle">
                    <span>￥{{ price }}</span>
                    <span>立即支付</span>
                </div>
            </div>
            <div class="pay-code-box" v-if="type === 0">
                <div v-if="platform === 2" id="qrDiv" style="display: none;"></div>
                <div v-if="platform === 2" id="qrcode" tabindex="0"></div>
                <!-- tabindex="0"：长按聚集识别二维码 -->
                <div class="pay-code-box-right">
                    <span class="price">￥{{ price }}<span>元</span></span>
                    <span class="tips">微信扫码支付</span>
                    <!-- <span class="pay-type-title">选择支付方式</span>
                     -->
                    <!-- <div class="xieyi">
                        <span class="xieyi-span1">服务协议</span>
                        <span class="xieyi-span2">购买须知</span>
                    </div> -->
                </div>
            </div>
            <div class="xieyi">
                购买即表示接受<span @click="goProtocol(3)">《青峰见财讯用户付款协议》</span>
            </div>
        </div>
    </div>
</template>

<script>
import { generateArticle, getPayState, generatePackage } from '@/api/pay.js'
import QRCode from 'qrcodejs2'
export default {
    name: "payBox",
    props: {
        isShow: {
            type: Boolean
        },
        price: {},
        payType: {
            type: Number
        },
        payId: {},
        orderInfo: {}
    },
    watch: {
        isShow(e) {
            this.show = e
        }
    },
    data() {
        return {
            type: 1,
            show: null,
            platform: 2,
            code: '',//支付检测code
            imgUrl: '',//支付二维码
            payStateDown: null
        }
    },
    methods: {
        /**
         * 重新选择支付方式
         */
        goBack() {
            clearInterval(this.payStateDown)
            this.type = 1
            document.getElementById('qrcode').innerHTML = ''
        },
        //生成二维码
        qrcode() {
            let nowUrl = this.imgUrl
            let qrcode = new QRCode('qrDiv', {
                width: 225, // 设置宽度，单位像素
                height: 225, // 设置高度，单位像素
                text: nowUrl // 设置二维码内容或跳转地址
            })
            console.log('二维码', qrcode);
            var mycanvas1 = document.getElementsByTagName('canvas')[0];
            //将转换后的img标签插入到html中  
            var img = this.convertCanvasToImage(mycanvas1);
            document.getElementById('qrcode').append(img);//imagQrDiv表示你要插入的容器id        
        },
        //从 canvas 提取图片 image  
        convertCanvasToImage(canvas) {
            //新Image对象，可以理解为DOM  
            var image = new Image();
            // canvas.toDataURL 返回的是一串Base64编码的URL，当然,浏览器自己肯定支持  
            // 指定格式 PNG  
            image.src = canvas.toDataURL("image/png");
            return image;
        },
        /**
         * 关闭弹窗
         */
        closeBox() {
            clearInterval(this.payStateDown)
            this.type = 1
            this.$emit('close')
        },
        /**
         * 生成订单
         */
        generateArticle() {
            if (this.platform === 2) {
                this.type = 0
            }
            clearInterval(this.payStateDown)
            let data = {
                platform: this.platform,
            }
            if (this.payType == 1) {
                data.packageId = this.payId
                generatePackage(data).then(res => {
                    this.code = res.code
                    this.getPayState()
                    if (this.platform === 3) {
                        window.open(res.data)
                    } else {
                        this.imgUrl = res.data
                        this.qrcode()
                    }
                })
            } else {
                data.articleId = this.payId
                generateArticle(data).then(res => {
                    this.code = res.code
                    this.getPayState()
                    if (this.platform === 3) {
                        window.open(res.data)
                    } else {
                        this.imgUrl = res.data
                        this.qrcode()
                    }
                })
            }
        },
        /**
         * 查询支付结果
         */
        getPayState() {
            this.payStateDown = setInterval(() => {
                getPayState(this.code).then(res => {
                    if (res) {
                        clearInterval(this.payStateDown)
                        this.$message.success('支付成功');
                        this.$router.push({ path: "/refresh" });
                    }
                })
            }, 1000)
        },
        /**
     * 跳转隐私协议
     */
        goProtocol(type) {
            this.$router.push({ path: '/protocol', query: { type: type } })
        },
    }
}
</script>

<style scoped>
.pay-box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
}

.pay-main {
    width: 792px;
    background: #FFFFFF;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.close-icon {
    position: absolute;
    right: 16px;
    top: 24px;
    width: 36px;
    height: 36px;
    cursor: pointer;
}

.back-icon {
    position: absolute;
    left: 16px;
    top: 24px;
    width: 36px;
    height: 36px;
    cursor: pointer;
}

.order-info {
    padding: 20px 0;
    width: 700px;
    margin-top: 4px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid #CDD2D9;
}

.order-info .order-banner {
    width: 220px;
    height: 114px;
    margin-right: 16px;
}

.order-main {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.order-title {
    color: #06121E;
    font-size: 18px;
    font-weight: bold;
}

.order-title .blue {
    color: #4181B8;
    margin-right: 8px;
}

.description {
    margin-top: 16px;
    font-size: 16px;
    color: #06121E;
    line-height: 1.5;
}

.order-bottom {
    margin-top: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.order-bottom .order-bottom-txt1 {
    width: 32px;
    height: 18px;
    color: #DD0722;
    font-size: 12px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #DD0722;
}

.order-bottom .order-bottom-txt2 {
    color: #BDC4CE;
    font-size: 12px;
}

.pay-title {
    margin-top: 30px;
    color: #333333;
    font-size: 16px;
    text-align: center;
}

.pay-code-box {
    width: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
}

.pay-code-box .code {
    width: 225px;
    height: 225px;
    margin-right: 50px;
}

.pay-code-box-right {
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.pay-code-box-right .price {
    color: #E2450A;
    font-size: 40px;
    display: flex;
    align-items: flex-end;
}

.pay-code-box-right .price span {
    font-size: 22px;
    margin-left: 10px;
    margin-bottom: 6px;
}

.pay-code-box-right .tips {
    margin-top: 80px;
    color: #666666;
    font-size: 16px;
}



.pay-type-title {
    color: #06121E;
    font-size: 16px;
    width: 700px;
    font-weight: bold;
    margin-top: 40px;
}

.pay-type {
    width: 700px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.pay-type .pay-type-item {
    width: 207px;
    height: 48px;
    background: #F3F4F7;
    border-radius: 28px 28px 28px 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 26px;
    border: 1px solid #F3F4F7;
    color: #979797;
    font-size: 16px;
    margin-top: 24px;
}

.pay-type .pay-type-item-active {
    background: rgba(221, 7, 34, 0.05);
    border: 1px solid #DD0722;
    color: #E54458;
}

.pay-type .pay-type-item img {
    width: 36px;
    height: 36px;
    margin-right: 10px;
}

.btn-box {
    width: 700px;
    margin-top: 48px;
}

.go-pay-btn {
    width: 285px;
    height: 56px;
    background: #DD0722;
    border-radius: 28px 28px 28px 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-size: 18px;
    cursor: pointer;
}

.go-pay-btn span:first-child {
    margin-right: 22px;
}

.xieyi {
    width: 792px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 48px;
    background-color: #F7F7F8;
    padding: 0 44px;
    font-size: 14px;
    color: #979797;
}

.xieyi span {
    color: #1296DB;
    font-size: 14px;
    margin-left: 8px;
    cursor: pointer;
}</style>
